<template>
 <PageHeading
  :button-title="$t('new rule')"
  :has-meta-data="false"
  :has-search="true"
  :has-action-button="true"
  :title="$t('inbound rules')"
  :has-permission="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.create')
  "
  @open="(this.openForm = !this.openForm), (this.openUpdateForm = false)"
  @update:search="$emit('update:search', $event)" />
 <div class="flex flex-col" v-if="this.openForm">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="rounded overflow-hidden border border-gray-200">
     <CreateNewCallQueue
      :hostName="hostName"
      :hostsNameOptions="hostsNameOptions"
      :hostsOptions="hostsOptions"
      :callQueuesOptions="callQueuesOptions"
      :title="$t('callqueue.newFormTitle')"
      :subTitle="$t('callqueue.formSubTitle')"
      :callQueuesGroups="this.callQueuesGroups"
      :editData="editData"
      :openUpdateForm="openUpdateForm"
      @activeBanner="$emit('activeBanner'), getCallQueuesLists()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
      @closeForm="this.openForm = false" />
    </div>
   </div>
  </div>
 </div>
 <div class="flex flex-col" v-if="this.openUpdateForm">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="rounded overflow-hidden shadow">
     <CreateNewCallQueue
      :hostName="hostName"
      :hostsNameOptions="hostsNameOptions"
      :hostsOptions="hostsOptions"
      :callQueuesOptions="callQueuesOptions"
      :title="$t('callqueue.updateFormTitle')"
      :subTitle="$t('callqueue.formSubTitle')"
      :callQueuesGroups="this.callQueuesGroups"
      :editData="editData"
      :openUpdateForm="openUpdateForm"
      @activeBanner="$emit('activeBanner'), getCallQueuesLists()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
      @closeForm="this.openUpdateForm = false" />
     <!--   <UpdateCallQueueForm
      v-on:closeForm="this.openUpdateForm = false"
      :title="$t('callqueue.updateFormTitle')"
      :id="this.callQueueId"
      :name="this.callQueueName"
      :callQueuesGroups="this.callQueuesGroups"
      @activeBanner="
       (activeBanner = true), getCallQueuesLists(), (key = !key), (this.openUpdateForm = false)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event"
     /> -->
    </div>
   </div>
  </div>
 </div>

 <div class="flex flex-col">
  <div class="-my-0 sm:-mx-6 lg:-mx-0">
   <div
    v-if="callQueuesLists?.length > 0"
    class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <CallQueuesTable
     :account="this.account"
     :thead="thead"
     :tbody="filterCallQueuesLists()"
     @openEditForm="openEditForm($event)"
     @deleteList="(...args) => deleteList(...args)"
     @activeBanner="$emit('activeBanner')"
     @successMsg="$emit('successMsg', $event)"
     @success="$emit('success', $event)"
     @sort="(this.currentSort = $event), sort($event)" />
   </div>

   <div v-else class="justify-center pt-20">
    <EmptyState
     :title="$t('emptyStateTitle', { title: $t('callQueue') })"
     :subtitle="$t('emptyStateSubtitle')" />
   </div>
  </div>
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import { PlusIcon } from "@heroicons/vue/solid";
import TablePagination from "../components/TablePagination.vue";
import CallQueuesHeader from "../components/CallQueuesHeader.vue";
import SearchFilter from "../components/SearchFilter.vue";
import CreateNewCallQueue from "../components/CreateNewCallQueue.vue";
import UpdateCallQueueForm from "../components/UpdateCallQueueForm.vue";
import SuccessBanner from "../components/SuccessBanner.vue";
import CallQueuesTable from "../components/CallQueuesTable.vue";
import EmptyState from "../components/EmptyState.vue";
import PageHeading from "../components/PageHeading.vue";
import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import { mapGetters } from "vuex";

const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 props: ["search"],
 components: {
  EmptyState,
  PageHeading,
  PlusIcon,
  TablePagination,
  CallQueuesHeader,
  CallQueuesTable,
  SearchFilter,
  CreateNewCallQueue,
  UpdateCallQueueForm,
  SuccessBanner,
  AskConfirmationDialog,
 },
 data() {
  return {
   thead: [
    { name: "Nom de la règle", sort: "name", isSort: false },
    { name: "Active", sort: "active", isSort: false },
    { name: "Type de ressource", sort: "type", isSort: false },
    { name: "Ressource", sort: "number", isSort: false },
   ],
   callQueuesLists: [],
   callQueuesGroups: [],
   callQueueData: {},
   account,
   openForm: false,
   currentSort: "id",
   currentSortDir: "asc",
   callQueueId: "",
   callQueueName: "",
   openUpdateForm: false,
   key: false,
   userPermissions,
   callQueuesOptions: null,
   hostsNameOptions: [],
   hostsOptions: {},
  };
 },
 methods: {
  async deleteList(...args) {
   let id = args[0];
   let name = args[1];
   let title = this.$t("Supprimer la règle entrante", { name: name });
   let confirmationMessage = this.$t("wishToContinue");
   let confirmButton = this.$t("delete");
   let goBack = this.$t("cancel");
   let successMsg = this.$t("elementDeletedSuccessfully", {
    element: this.$t("Règle entrante"),
   });
   const options = {
    method: "DELETE",
    url: `${this.$cookie.getCookie("API")}/api/v1/callQueues/${id}`,
    params: { customerAccount: this.account },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .request(options)
     .then(response => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", successMsg);
      this.getCallQueuesLists();
     })
     .catch(err => {
      console.error(err);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  openEditForm(data) {
   this.openForm = false;
   this.openUpdateForm = true;
   this.callQueueId = data.id;
   this.editData = data;
   window.scrollTo(0, 100);
  },
  async getCallQueuesLists() {
   this.$emit("setLoading", true);
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/callQueues?customerAccount=${
     this.account
    }`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   this.$emit("setLoading", false);

   try {
    this.callQueuesLists = res.data;
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
   }
  },
  async getCallQueuesOptions() {
   this.$emit("setLoading", true);
   const res = await axios.get(
    `${this.$cookie.getCookie(
     "API"
    )}/api/v1/callQueues/options?customerAccount=${this.account}`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   this.$emit("setLoading", false);

   try {
    if (res?.data?.hosts) {
     this.hostsNameOptions = Object.keys(res?.data?.hosts);
     this.hostsOptions = res?.data?.hosts;
     if (this.hostName) {
      this.callQueuesOptions = res.data?.hosts[this.hostName];
     } else {
      this.callQueuesOptions = res.data?.hosts[0];
     }
     // this.callQueuesDIDOptions = res.data?.dids;
     // this.callQueuesExtensionOptions = res.data?.extensions;
     // this.callQueuesQueueOptions = res.data?.queues;
    }
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getCallQueuesGroups() {
   this.$emit("setLoading", true);
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/callQueueGroups?customerAccount=${
     this.account
    }`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   this.$emit("setLoading", false);

   try {
    this.callQueuesGroups = res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedCallQueues() {
   return this.callQueuesLists.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "number") {
     if (parseFloat(a[toBeSorted]) < parseFloat(b[toBeSorted]))
      return -1 * modifier;
     if (parseFloat(a[toBeSorted]) > parseFloat(b[toBeSorted]))
      return 1 * modifier;
    } else if (typeof a[toBeSorted] === "string") {
     if (a[toBeSorted].toLowerCase() < b[toBeSorted].toLowerCase())
      return -1 * modifier;
     if (a[toBeSorted].toLowerCase() > b[toBeSorted].toLowerCase())
      return 1 * modifier;
    }
    return 0;
   });
  },
  filterCallQueuesLists() {
   return this.sortedCallQueues().filter(res => {
    if (this.search) {
     const searchLower = this.search.toLowerCase();

     const nameMatch = res.name?.toLowerCase().includes(searchLower);
     const numberMatch = res.number?.includes(searchLower);
     const didNumberMatch = res.did_number?.includes(searchLower);
     const typeMatch = res.type?.toLowerCase().includes(searchLower);

     return nameMatch || numberMatch || typeMatch || didNumberMatch;
    }
    return res;
   });
  },
 },
 mounted() {
  this.getCallQueuesLists();
  this.getCallQueuesGroups();
  this.getCallQueuesOptions();
 },
 computed: {
  ...mapGetters(["hostName"]),
 },
};
</script>

<style></style>
